import React, { useState } from "react";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";

const Contacto = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const SERVICE_ID = "service_w90axlg";
  const TEMPLATE_ID = "template_pmppyo8";
  const USER_ID = "user_sH0l1DDMXHxEjTPk4CrFb";

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send(SERVICE_ID, TEMPLATE_ID, { name, email, phone, message }, USER_ID)
      .then(
        (result) => {
          Swal.fire({
            icon: "success",
            title: "Su solicitud se ha enviado correctamente!",
          });
          resetForm();
        },
        (error) => {
          Swal.fire({
            icon: "error",
            title: "Ooops, no se ha podido enviar la solicitud!",
          });
          resetForm();
        }
      );
    e.target.reset();
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };

  return (
    <div className="contact-us-area default-padding-bottom" id="contacto">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 address text-light">
            <div className="address-items wow fadeInUp">
              <div className="item">
                <h4>Ubicación</h4>
                <div className="info">
                  <p>
                    Lavalle 1605,
                    <br />
                    Concordia, Entre Ríos,
                    <br />
                    Argentina
                  </p>
                </div>
              </div>
              <div className="item">
                <h4>Contacto</h4>
                <div className="info">
                  <ul>
                    <li>
                      Teléfono: <span>+54 9 3454 19-5549</span>
                    </li>
                    <li>
                      Email: <span>contacto@estudioastralis.com</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-8 contact-form wow fadeInLeft" id="contacto">
            <h3>
              Solicite una cotización <strong>gratis</strong>
            </h3>
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <input
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="Nombre *"
                      type="text"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      required
                      autoComplete="off"
                    />
                    <span className="alert-error"></span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Correo Electrónico*"
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required
                      autoComplete="off"
                    />
                    <span className="alert-error"></span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      className="form-control"
                      id="phone"
                      name="phone"
                      placeholder="Número de Teléfono *"
                      type="text"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                      required
                      autoComplete="off"
                    />
                    <span className="alert-error"></span>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group comments">
                    <textarea
                      className="form-control"
                      id="comments"
                      name="message"
                      placeholder="Cuéntanos sobre tu proyecto *"
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      required
                      autoComplete="off"
                    ></textarea>
                  </div>
                </div>
                <div className="col-lg-12 text-right">
                  <button type="submit" name="submit" id="submit">
                    Solicitar Cotización <i className="fa fa-paper-plane"></i>
                  </button>
                </div>
                <div className="col-lg-12 alert-notification">
                  <div id="message" className="alert-msg"></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacto;
