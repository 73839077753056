import React from "react";

const Servicios = () => {
  return (
    <div className="services-area carousel-shadow" id="servicios">
      <div className="shape-left-bottom shape opacity-default">
        <img src="assets/img/shape/4.png" alt="Shape" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div
              className="site-heading text-center"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h5>Nuestros Servicios</h5>
              <div className="heading-divider"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg">
        <div className="services-items wow fadeInUp services-stage-carousel owl-carousel owl-theme text-center">
          <div className="item">
            <div className="icon">
              <i className="flaticon-bullhorn"></i>
            </div>
            <div className="info">
              <h4>
                <a href="#contacto">Estrategia de Contenidos</a>
              </h4>
              <p>
                - Planificación <br />
                - Investigación <br />- Elaboración de piezas digitales
              </p>
              <a href="#contacto">
                <i className="fas fa-arrow-right"></i> Solicitar
              </a>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <i className="flaticon-keyword-1"></i>
            </div>
            <div className="info">
              <h4>
                <a href="#contacto">Posicionamiento</a>
              </h4>
              <p>
                - Redacción SEO <br />
                - Optimización SEO <br />
                - Analítica Web <br />
              </p>
              <a href="#contacto">
                <i className="fas fa-arrow-right"></i> Solicitar
              </a>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <i className="flaticon-ppc"></i>
            </div>
            <div className="info">
              <h4>
                <a href="#contacto">Desarrollo</a>
              </h4>
              <p>
                - Páginas Web <br />
                - E-Commerce <br />- Landing Page
              </p>
              <a href="#contacto">
                <i className="fas fa-arrow-right"></i> Solicitar
              </a>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <i className="flaticon-competition"></i>
            </div>
            <div className="info">
              <h4>
                <a href="#contacto">Conversión</a>
              </h4>
              <p>
                - Comercialización <br />
                - Consultoría de Ventas <br />- Embudos de Leads
              </p>
              <a href="#contacto">
                <i className="fas fa-arrow-right"></i> Solicitar
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Servicios;
