import React from "react";

const Header = () => {
  return (
    <header id="home">
      <nav className="navbar navbar-default attr-bg navbar-fixed white no-background bootsnav">
        <div className="container-full">
          <div className="attr-nav extra-color">
            <ul>
              <li className="side-menu">
                <a
                  href="https://api.whatsapp.com/send?phone=5493454195549"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-whatsapp"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle"
              data-toggle="collapse"
              data-target="#navbar-menu"
            >
              <i className="fa fa-bars"></i>
            </button>
            <a className="navbar-brand" href="#">
              <img
                src="assets/img/logo_estudio_astralis_light.png"
                className="logo logo-display"
                alt="Logo"
              />
              <img
                src="assets/img/logo_estudio_astralis_dark.png"
                className="logo logo-scrolled"
                alt="Logo"
              />
            </a>
          </div>
          <div className="collapse navbar-collapse" id="navbar-menu">
            <ul
              className="nav navbar-nav navbar-center"
              data-in="#"
              data-out="#"
            >
              <li>
                <a href="#">
                  <span className="opcion-menu">Inicio</span>
                </a>
              </li>
              <li>
                <a href="#sobre-nosotros">
                  <span className="opcion-menu">Sobre Nosotros</span>
                </a>
              </li>
              <li>
                <a href="#servicios">
                  <span className="opcion-menu">Servicios</span>
                </a>
              </li>
              {/* <li>
                <a href="#equipo">
                  <span className="opcion-menu">Equipo</span>
                </a>
              </li> */}
              <li>
                <a href="#clientes">
                  <span className="opcion-menu">Clientes</span>
                </a>
              </li>
              <li>
                <a href="#contacto">
                  <span className="opcion-menu">Contacto</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
