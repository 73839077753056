import React from "react";

const Banner = () => {
  return (
    <div className="banner-area circle-shape text-center auto-height text-light heading-capitalized bg-gradient">
      <div className="shape-left-bottom">
        <img src="assets/img/shape/bg-3.png" alt="Shape" />
      </div>
      <div className="container">
        <div className="content-box">
          <div className="row align-center">
            <div className="col-lg-8 offset-lg-2 info">
              <h2 className="wow fadeInDown" data-wow-duration="1s">
                Agencia de Comunicación<strong>disruptiva</strong>
              </h2>
              <a
                className="btn circle btn-md btn-light effect wow fadeInUp"
                data-wow-duration="1.8s"
                href="#contacto"
              >
                Conecta con Nosotros
              </a>
            </div>
            <div
              className="col-lg-8 offset-lg-2 wow fadeInRight"
              data-wow-duration="1s"
            >
              <div className="thumb-innner">
                <img src="assets/img/illustration/5.png" alt="Thumb" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
