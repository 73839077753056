import React from "react";
import ReactDOM from "react-dom";
import "./style.css";

//  Componentes
import Header from "./components/Header";
import Banner from "./components/Banner";
import SobreNosotros from "./components/SobreNosotros";
import Servicios from "./components/Servicios";
// import Equipo from "./components/Equipo";
import Clientes from "./components/Clientes";
import Contacto from "./components/Contacto";
import Footer from "./components/Footer";

ReactDOM.render(
  <React.StrictMode>
    <Header />
    <Banner />
    <SobreNosotros />
    <Servicios />
    {/* <Equipo /> */}
    <Clientes />
    <Contacto />
    <Footer />
  </React.StrictMode>,
  document.getElementById("root")
);
