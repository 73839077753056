import React from "react";

const Clientes = () => {
  return (
    <div
      className="clients-area text-light bg-gradient default-padding-mx"
      id="clientes"
    >
      <div
        className="fixed-bg"
        style={{ backgroundImage: "url(assets/img/map.svg)" }}
      ></div>
      <div className="container">
        <div className="row align-center">
          <div className="col-lg-5 info">
            <h3>
              Clientes que nos eligen como su{" "}
              <strong>
                <u>SOCIO TECNOLÓGICO</u>
              </strong>
            </h3>
          </div>
          <div className="col-lg-7">
            <div className="clients-carousel owl-carousel owl-theme">
              <div className="item">
                <img
                  src="assets/img/clientes/establecimiento_don_alejo.png"
                  alt="Establecimiento Don Alejo"
                />
              </div>
              <div className="item">
                <img
                  src="assets/img/clientes/focus_servicios_integrales.png"
                  alt="Focus - Servicios Integrales"
                />
              </div>
              <div className="item">
                <img
                  src="assets/img/clientes/foroprof.png"
                  alt="ForoProf.com"
                />
              </div>
              <div className="item">
                <img src="assets/img/clientes/greentech.png" alt="Greentech" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="shape-bottom">
        <img src="assets/img/shape/8.png" alt="Shape" />
      </div>
    </div>
  );
};

export default Clientes;
