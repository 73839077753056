import React from "react";

const SobreNosotros = () => {
  return (
    <div className="about-features-area about-area" id="sobre-nosotros">
      <div className="container">
        <div className="row align-center">
          <div className="col-lg-6 info wow fadeInLeft">
            <h5>Sobre Nosotros</h5>
            <h3>¿Por qué somos una agencia disruptiva?</h3>
            <p>
              La transformación digital nos sacudió a todos con un tsunami de
              información y datos. Entre tanto ruido de Marketinero, Estudio
              Astralis{" "}
              <strong>
                conecta a marcas con personas de forma sofisticada.
              </strong>
            </p>
            <h4>
              <strong>
                No hacemos marketing <br />
                Le hablamos a tu público objetivo 😉
              </strong>
            </h4>
          </div>
          <div className="col-lg-6 process-box text-center">
            <div className="row">
              <div className="item-grid col-lg-6 col-md-6">
                <div className="item wow fadeInUp" data-wow-delay="500ms">
                  <div className="icon">
                    <i className="fas fa-archway"></i>
                  </div>
                  <h4>Landing Page</h4>
                  <h6>
                    Página de aterrizaje para aquellos que quieran dar un primer
                    paso en el contenido web sin comprometer una gran inversión.
                  </h6>
                  <a href="#contacto">
                    <button name="submit" id="submit" style={{ width: "100%" }}>
                      Cotizar <i className="fa fa-paper-plane"></i>
                    </button>
                  </a>
                </div>
                <div className="item wow fadeInUp" data-wow-delay="500ms">
                  <div className="icon">
                    <i className="fas fa-palette"></i>
                  </div>
                  <h4>Página Web</h4>
                  <h6>
                    Ideal para empresas o negocios que estén interesados en dar
                    un salto de calidad en su comunicación.
                  </h6>
                  <a href="#contacto">
                    <button name="submit" id="submit" style={{ width: "100%" }}>
                      Cotizar <i className="fa fa-paper-plane"></i>
                    </button>
                  </a>
                </div>
              </div>
              <div
                className="item-grid col-lg-6 col-md-6"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className="item wow fadeInUp" data-wow-delay="500ms">
                  <div className="icon">
                    <i className="fas fa-store"></i>
                  </div>
                  <h4>E-Commerce</h4>
                  <h6>
                    Excelente opción para aquellos que deseen vender sus
                    productos y servicios por internet de manera sencilla.
                  </h6>
                  <a href="#contacto">
                    <button name="submit" id="submit" style={{ width: "100%" }}>
                      Cotizar <i className="fa fa-paper-plane"></i>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SobreNosotros;
