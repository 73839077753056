import React from "react";
// Tawk
import TawkTo from "tawkto-react";

const Footer = () => {
  const tawk = new TawkTo("61faea579bd1f31184daa6b1", "1fqu26pi4");

  return (
    <footer className="bg-dark text-light">
      <div className="svg-shape">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="light"
          preserveAspectRatio="none"
          viewBox="0 0 1070 52"
        >
          <path d="M0,0S247,91,505,32c261.17-59.72,565-13,565-13V0Z"></path>
        </svg>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-12 copyright">
              <p style={{ fontWeight: "bold" }}>
                Copyright &copy; 2022. Designed by Estudio Astralis.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
